export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'Sign in',
        url: `/login`,
        cName: 'nav-links'
    },
    {
        title: 'Youtube',
        url: 'youtube',
        cName: 'nav-links'
    },
    {
        title: 'Analysis',
        url: 'analysis',
        cName: 'nav-links'
    },
    {
        title: 'My page',
        url: 'mypage',
        cName: 'nav-links'
    }
]