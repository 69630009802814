export const locationData = [
"사직동", 
"삼청동", 
"부암동", 
"평창동",
"무악동",
"교남동",
"가회동",
"종로동",
"이화동",
"창신동",
"숭인동",
"청운효자동",
"혜화동",
"소공동",
"회현동",
"명동",
"필동",
"장충동",
"광희동",
"을지로동",
"신당동",
"황학동",
"중림동",
"다산동",
"약수동",
"청구동",
"동화동",
"후암동",
"용산동",
"남영동",
"효창동",
"용문동",
"이촌동",
"이태원동",
"서빙고동",
"보광동",
"청파동",
"원효로동",
"한강로동",
"한남동",
"왕십리동",
"마장동",
"사근동",
"행당동",
"응봉동",
"성수동",
"송정동",
"용답동",
"왕십리도선동",
"금호동",
"옥수동",
"화양동",
"군자동",
"중곡동",
"능동",
"구의동",
"광장동",
"자양동",
"회기동",
"휘경동",
"청량리동",
"용신동",
"제기동",
"전농동",
"장안동",
"이문동",
"답십리동",
"상봉동",
"중화동",
"묵동",
"신내동",
"면목동",
"망우동",
"돈암동",
"안암동",
"보문동",
"정릉동",
"길음동",
"월곡동",
"장위동",
"성북동",
"삼선동",
"동선동",
"종암동",
"석관동",
"번동",
"수유동",
"삼양동",
"미아동",
"송중동",
"송천동",
"삼각산동",
"우이동",
"인수동",
"쌍문동",
"방학동",
"창동",
"도봉동",
"월계동",
"하계동",
"중계본동",
"상계동",
"중계동",
"공릉동",
"녹번동",
"갈현동",
"구산동",
"대조동",
"증산동",
"수색동",
"진관동",
"불광동",
"응암동",
"역촌동",
"천연동",
"홍제동",
"남가좌동",
"북가좌동",
"충현동",
"북아현동",
"신촌동",
"연희동",
"용강동",
"대흥동",
"염리동",
"신수동",
"서교동",
"합정동",
"망원동",
"연남동",
"성산동",
"상암동",
"도화동",
"서강동",
"공덕동",
"아현동",
"목동",
"신월동",
"신정동",
"염창동",
"등촌동",
"가양동",
"발산동",
"공항동",
"방화동",
"화곡동",
"우장산동",
"신도림동",
"고척동",
"수궁동",
"가리봉동",
"구로동",
"개봉동",
"오류동",
"항동",
"가산동",
"독산동",
"시흥동",
"여의동",
"당산동",
"양평동",
"신길동",
"대림동",
"영등포동",
"도림동",
"문래동",
"상도동",
"사당동",
"대방동",
"신대방동",
"흑석동",
"노량진동",
"보라매동",
"청림동",
"행운동",
"낙성대동",
"중앙동",
"인의동",
"인헌동",
"남현동",
"서원동",
"신원동",
"서림동",
"신림동",
"난향동",
"조원동",
"대학동",
"은천동",
"성현동",
"청룡동",
"난곡동",
"미성동",
"서초동",
"잠원동",
"반포동",
"방배동",
"양재동",
"내곡동",
"신사동",
"논현동",
"삼성동",
"역삼동",
"도곡동",
"일원동",
"수서동",
"세곡동",
"압구정동",
"청담동",
"대치동",
"개포동",
"풍납동",
"거여동",
"마천동",
"방이동",
"오륜동",
"오금동",
"송파동",
"석촌동",
"삼전동",
"가락동",
"문정동",
"장지동",
"위례동",
"잠실동",
"강일동",
"상일동",
"명일동",
"고덕동",
"암사동",
"천호동",
"성내동",
"둔촌동",
"암사동",
"길동"
]